import React, { useState } from "react";
import logosm from "../assets/images/logo/logo.png";
import logow from "../assets/images/logo/white.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import Add_Restaurant from '../components/add-restaurant';

const Footer = () => {
    const [addrestroShow, setAddrestroShow] = useState(false);
    return (

        <>
            <section className="footer mt-5">
                <div className="container-lg">
                    <div className="row text-sm-center ">
                        <div className="col-lg-3 col-md-12">
                            <img src={logosm} alt="" className="img-fluid" />
                            <img src={logow} alt="" className="img-fluid img2" />
                        </div>
                        <div className="col-lg-9 col-md-12 align-self-center footer-link">
                            <div className="d-lg-flex d-block justify-content-around">
                                <Link className="text-white text-decoration-none" to="/about-us">About us </Link>
                                <Link className="text-white text-decoration-none" to="/contact">Contact</Link>
                                <Link to="/privacy-policy" className="text-white text-decoration-none">Privacy Policy </Link>
                                <Link to="/term-and-conditions" className="text-white text-decoration-none">Term & Condition</Link>
                                <Link to="refund-and-cencellation" className="text-white text-decoration-none">Refund & Cencellations</Link>
                                <Link href="" className="text-white  text-decoration-none" onClick={() => setAddrestroShow(true)}>Partner with us </Link>
                            </div>


                        </div>
                     

                           
                            <div className="row justify-content-center  social-footer my-4 mt-lg-0">
                        <div className="col-lg-1 col-3">
                            <div className="d-flex justify-content-between">
                                <a href=" https://www.facebook.com/bhukkadsindia2021" target="_blank" className="text-white"><FaFacebookF size={20} /></a>
                                <a href=" https://www.instagram.com/bhukkads.in?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" className="text-white"><FaInstagram size={20} /></a>
                                <a href="https://www.linkedin.com/company/bhukkadsindia2021" className="text-white" target="_blank"><FaLinkedinIn size={20} /></a>


                            </div>

                        </div>

                    </div>

                    <div className="row mt-4 border-top">
                        <div className="col-12 text-center">
                            <p className="text-white py-4 mb-0 fs-12">© 2024 Copyright: Bhukkads.in. All Rights Reserved</p>
                        </div>
                    </div>
                        </div>
                    </div>

                

            
                <Add_Restaurant show={addrestroShow} setShow={setAddrestroShow} />

            </section>



        </>
    )
}


export default Footer