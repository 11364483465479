import React, { useContext, useEffect, useState } from "react";
import minimap from "../assets/images/others/Mini-Map.png"
import { FaMapMarkerAlt, FaTrash, FaTrashAlt } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import plus from "../assets/images/restaurant/add-circle.png";
import upi from "../assets/images/others/Upi.png";
import cash from "../assets/images/others/cash.png";
import minus from "../assets/images/restaurant/minus-circle.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Order_Success from "../components/order-success";
import { errorToast, requestGet, requestPost, successToast } from "../utils/call";
import { api } from "../urls";
import Select from 'react-select';
import { toast } from "react-toastify";
import { handleLocationClick } from "../utils/location";
import Login from "../components/login";
// import { OrderTypeContext } from "../provider-data/OrderType";
import Slot from "../components/slot";
import { DisplayRazorpay, loadScript } from "../payament";

const Confirm_Order = () => {
    // const {typeOfOrder,setTypeOfOrder} = useContext(OrderTypeContext)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [successShow, setSuccessShow] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const [cartList, setCartList] = useState({});
    const [restaurantDetail, setRestaurantDetail] = useState({});
    const [latLong, setLatLong] = useState({})
    const [selectList, setSelectList] = useState([])
    const [selectedOffer, setSelectedOffer] = useState("")
    const [slotShow, setSlotShow] = useState(false);
    const [restaurantID,setRestaurantID] = useState("")
    const [orderType,setOrderType] = useState("")


    useEffect(() => {
        const id = searchParams.get('id');
        const type = localStorage.getItem("orderType")
        let tableNo = Number(searchParams.get('tableno'))
           console.log("tablemo",tableNo);
           
        
        if(tableNo>0){
            setOrderType("DINING")
        }else {
            setOrderType(type)
        }
        
        if(!id){
            navigate("/")
        }
        setRestaurantID(id)
        fetchCartData()
        handleLocationClick((lat, long) => fetchRestaurantDetail(lat, long, id))
        loadScript()
    }, [])

    const fetchRestaurantDetail = async (lat, long, restaurantID) => {
        setLatLong({ lat, long })
        const res = await requestPost(api.getRestaurantDetails, {
            restaurantID,
            latitude: lat,
            longitude: long
        })
        if (!res.data) {
            setRestaurantDetail({})
        } else {
            // direction(res.data?.data?.location?.coordinates)
            setRestaurantDetail(res.data?.data) 


            
            res.data?.data?.offers?.map((item) => {
                setSelectList([...selectList, { value: item._id, label: item?.offerName + " | " + item?.offerValue }])
            })
        }
    }
    const fetchCartData = () => {
        const token = localStorage.getItem("token")
        if (token) {
            getCartData()
        } else {
            setCartList(JSON.parse(localStorage.getItem("cart")))
        }
    }

    const getCartData = () => {
        requestGet(api.getCartDetails).then((res) => {
            if (res.data) {
                res.data?.data?.dishes.forEach((el) => {
                    el["id"] = el.dish
                })
                setCartList(res.data.data)
            } else {
                setCartList({})
            }
        })
    }
    const addQuantity = (item) => {
        console.log("sss", item);
        const token = localStorage.getItem("token")
        if (!token && cartList) {
            cartList.dishes.forEach((el) => {
                if (el.id === item?.id && el.quantity < 11) {
                    el.quantity++
                }
            })
            setCartList({ ...cartList })
            localStorage.setItem("cart", JSON.stringify(cartList))
        }
        else if (cartList) {
            item.quantity = 1
            callCartApi({ restaurantID: cartList?.restaurantID, dishes: [item] })
        }

    }
    const callCartApi = (cart) => {
        console.log("data final call before apii", cart);
        requestPost(api.addInCart, cart).then((res) => {
            if (res.data) {
                fetchCartData()
                successToast(res)
            } else {
                // setSingleCartData(cart)
                // setAlertPopupShow(true)
            }
        })
    }

    const substractQuantity = (item) => {
        const token = localStorage.getItem("token")
        if (!token && cartList) {
            cartList.dishes.forEach((el) => {
                if (el.id === item?.id && el.quantity > 1) {
                    el.quantity--
                }
            })
            setCartList({ ...cartList })
            localStorage.setItem("cart", JSON.stringify(cartList))
        }
        else if (cartList) {
            {
                item.quantity = -1
                callCartApi({ restaurantID: cartList?.restaurantID, dishes: [item] })
            }
        }

    }

    const deleteCrt = async (item) => {
        let token = localStorage.getItem("token")
        if (token) {
            let res = await requestPost(api.deleteDishFromCart, { dishID: item?.id })
            if (res.data) {
                toast.success(`${item?.dishName} deleted from cart successfully`)
                fetchCartData()
            } else {
                errorToast(res)
            }
        } else {
            let cart = JSON.parse(localStorage.getItem("cart"))
            const index = cart?.dishes.findIndex(user => user.id === item?.id);
            if (index !== -1) {
                cart?.dishes.splice(index, 1);
                setCartList({ ...cart })
                localStorage.setItem("cart", JSON.stringify(cart))
                toast.success(`cart deleted successfully`)
            } else {
                console.log('User not found');
            }
        }

    }

    const direction = () => {
        const googleMapsDirectionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latLong.lat},${latLong.long}&destination=${restaurantDetail.location?.coordinates[1]},${restaurantDetail.location?.coordinates[0]}`;
        window.open(googleMapsDirectionsUrl, '_blank');
    }

    const applyOfferHandler = async (e) => {
        const token = localStorage.getItem("token")
        if (token) {
            let res =await requestPost(api.availOffer, { offerID: e?.value })
            if (res.data) {
                setSelectedOffer(e.value)
                console.log("resss");
                getCartData()
            }
        } else {
            setLoginShow(true)
        }

    }

    const handleSlot =async ()=>{
        const token = localStorage.getItem("token")
        if(orderType==="PRE_ORDER"){
            setSlotShow(true)
        }else if(orderType==="TAKE_AWAY"){
            if (token) {
                DisplayRazorpay(navigate)
                // let res =await requestPost(api.createOrder, { orderType: orderType })
                // if (res.data) {
                //     console.log("resss",res);
                //     setSuccessShow(true)
                // }
            } else {
                setLoginShow(true)
            }
        }else {
            if (token) {
                let res =await requestPost(api.createOrder, { orderType: orderType, tableNo:Number(searchParams.get('tableno'))})
                if (res.data) {
                    console.log("resss",res);
                    setSuccessShow(true)
                }
            } else {
                setLoginShow(true)
            }
        }
    }
    return (
        <>
            <nav aria-label="breadcrumb " className="mt-2 mb-5 ps-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item fs-12"><Link to="/" className="text-grayc text-decoration-none">Home</Link></li>
                    <li className="breadcrumb-item active text-black fs-12" aria-current="page">Confirm-Order</li>
                </ol>
            </nav>
            <section className="my-5 ">
                <div className="container-fluid">
                    <div className="row justify-content-center flex-wrap-reverse">
                        <div className="col-md-6 col-lg-5 mt-4 mt-lg-0">
                            <div className="confirm-order">
                                {/* <div className="time-mode d-flex justify-content-around b-dishes mx-4">
                                    <p className="text-bluec fw-600">Dine in</p>
                                    <p className="text-successc fs-14 align-middle pt-1">2:00 to 4:00 PM</p>
                                    <p className="text-grayc fs-14 pt-1">Tuesday, 03 March 2023</p>
                                </div> */}
                                <div className="row mt-2 justify-content-around mx-4 b-dishes py-3">
                                    <div className="col-3 cPointer" onClick={direction}>
                                        <img src={minimap} alt="" className="img-fluid" />
                                    </div>
                                    <div className="col-8">
                                        <p className="text-bluec fs-13">{restaurantDetail?.address} , contact -{restaurantDetail?.contact}</p>
                                        <a href="#" className="text-grayc text-decoration-none"><FaMapMarkerAlt /> <span className="align-middle">{restaurantDetail?.distanceKM} km</span></a>
                                    </div>
                                </div>
                                <div className="row   px-2 b-dishes py-3 mx-4">
                                    <div className="col-lg-6  col-8">
                                        <p className="text-bluec mb-0 fs-14">Subtotal </p>
                                    </div>
                                    <div className="col-lg-4 col-4">
                                        <p className="text-black mb-0 fs-14 price ">₹ {cartList?.cartTotalPrice}</p>
                                    </div>
                                </div>
                                <div className="row   px-2 b-dishes py-3 mx-4">
                                    <div className="col-lg-6  col-8">
                                        <p className="text-bluec mb-0 fs-14">Taxes & Charges</p>
                                    </div>
                                    <div className="col-lg-4 col-4">
                                        <p className="text-black mb-0 fs-14 price">₹ {cartList?.taxAndCharges?.toFixed(2)}</p>

                                    </div>
                                </div>
                                <div className="row   px-lg-2 b-dishes py-3 mx-4">
                                    <div className="col-lg-4 col-4 align-self-center">
                                        <p className="text-bluec mb-0 fs-14">Voucher</p>
                                    </div>
                                    <div className="col-8 col-lg-6">
                                        <Select
                                            onChange={applyOfferHandler}
                                            placeholder="Select Offer"
                                            className='form-control-select voucher '
                                            options={selectList}
                                        />
                                    </div>
                                </div>{
                                    cartList?.discountedAmount > 0 &&
                                    <div className="row   px-2 b-dishes py-3 mx-4">
                                        <div className="col-6">
                                            <p className="text-bluec mb-0 fs-14">Discount</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-black mb-0 fs-14 price">₹ {cartList?.discountedAmount?.toFixed(2)}</p>

                                        </div>
                                    </div>}

                                <div className="row   px-2 b-dishes py-3 mx-4">
                                    <div className="col-lg-6  col-8">
                                        <p className="text-bluec mb-0 fw-bold">Total</p>
                                    </div>
                                    <div className="col-lg-4 col-4">
                                        <p className="text-pink mb-0 fw-bold price">₹ {cartList?.finalAmount?.toFixed(2) }</p>
                                    </div>
                                </div>
                                <div className="row  py-3 mx-lg-4 justify-content-center">
                                    <div className="col-lg-5   col-6">
                                       <div className="paymode d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <img src={upi} alt="" className="img-fluid" />
                                        </div>
                                        <div className="">
                                       <p className="fs-16 text-pink mb-0"> 
                                       ₹ {cartList?.finalAmount?.toFixed(2) }</p>
                                        <p className="mb-0 fw-600">Paypal</p>
                                        </div>
                                       </div>
                                    </div>
                                    <div className="col-lg-5 col-6">
                                    <div className="paymode d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <img src={cash} alt="" className="img-fluid" />
                                        </div>
                                        <div >
                                        <p className="fs-16 text-pink mb-0">
                                        ₹ {cartList?.finalAmount?.toFixed(2) }</p>
                                        <p className="mb-0 fw-600">Cash</p>
                                        </div>
                                       </div>
                                    </div>
                                </div>
                                <div className="row mt-4 justify-content-center">
                                    <div className="col-6">
                                        <div  className="btn btn-custom w-100" onClick={handleSlot}>Checkout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="cart-confirm cart">
                                <div className="row justify-content-center">
                                    <div className="col-11 ">{
                                        cartList.dishes?.map((item) => {

                                            return <div className="row b-dishes py-3">
                                                <div className="col-3 px-0">
                                                    <img src={item?.dishImages[0]} alt="" className="img-fluid dish-img" />
                                                </div>
                                                <div className="col-9">
                                                    <h6 class="text-bluec fw-500">{item.dishName}</h6>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="d-flex">
                                                            <div className="inc-btn">
                                                                <img src={minus} alt="" className="img-fluid" onClick={() => substractQuantity(item)} />
                                                                <span className="px-3">{item?.quantity}</span>
                                                                <img src={plus} alt="" className="img-fluid" onClick={() => addQuantity(item)} />
                                                            </div>
                                                            <div className="price text-dark ms-3 align-self-center"> ₹ {item?.totalPrice} </div>
                                                        </div>
                                                        <div className="price text-danger cPointer ms-3 align-self-center" onClick={() => deleteCrt(item)}><FaTrashAlt className="fs-5" /></div>
                                                    </div>
                                                </div>
                                            </div>

                                        })}

                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>


                </div>
            </section>

            <Order_Success show={successShow} setShow={setSuccessShow} />
            <Login show={loginShow} setShow={setLoginShow} />
            <Slot show={slotShow} setShow={setSlotShow}restaurantID={searchParams.get('id')} />

        </>
    )
}

export default Confirm_Order;