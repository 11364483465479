export const getDateAndTime = (data) => {
    const date = new Date(data);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = date.getUTCFullYear();
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();

    // Format the date
    const formattedDate = `${day}/${month}/${year}`;

    // Get the hours and convert to 12-hour format
    let hours = date.getUTCHours();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Format the time
    const formattedTime = `${hours} ${period}`;
    return { formattedDate, formattedTime }
}