import axios from "axios"
import { baseUrl } from "../urls"
import {  toast } from 'react-toastify';

export const requestPost =async (api,body)=>{
    const token= localStorage.getItem("token");
    const headers = {
        headers:
          { Authorization: `Bearer ${token}` }
      };
        try{
        return await axios.post(baseUrl+api,body,headers).then((res)=>{
            return {error:null,data:res.data}
         })
        }catch(error){
            return {error,data:null}
        }   
     
}
export const requestGet =async (api)=>{
    const token= localStorage.getItem("token");
    const headers = { headers:
          { Authorization: `Bearer ${token}` }
      };
        try{
          return await  axios.get(baseUrl+api,headers).then((res)=>{
               return {error:null,data:res.data}
            })
           }catch(error){
               return {error,data:null}
           }
    
     
}

export const  errorToast = (error)=>{
    toast.error(error?.error.response?.data?.message)
}
export const successToast = (res) =>{
    console.log(res);
    toast.success(res.data?.message)
}