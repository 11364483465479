
// export const baseUrl = "http://localhost:6500/"
export const baseUrl = "https://bhukkadmainbackend.Bhukkads.in/"

export const api ={
    getRestaurantsNearMe:"get-restaurants-near-me",
    getRestaurantDetails:"get-restaurant-details",
    getRestaurantsByGlobalCategories:"get-restaurants-by-global-categories",
    getGlobalCategories:"get-global-categories",
    getMenuByRestaurant:"get-dish-by-restaurant",
    getRestaurantsCategoryAndItsDishes:"get-restaurants-category-and-its-dishes",
    getRestaurantsByLevel2Category:"get-restaurants-by-level2-category",
    getRestaurantsByName:"get-restaurants-by-name",
    register:"register",
    verifyRegister:"verify-register",
    login:"login",
    verifyLogin:"verify-login",
    addInCart:"add-to-cart",
    deleteUserCart:"delete-user-cart",
    getCartDetails:"get-cart-details",
    sendMailToSuperAdmin:"send-mail-to-super-admin",
    deleteDishFromCart:"delete-dish-from-cart",
    getOfferByRestaurantId:"get-offer-by-restaurant-id",
    availOffer:"avail-offer",
    getProfile:"get-profile",
    updateProfile:"update-profile",
    getTimeSlot:"get-time-slot",
    getRestaurantsByGlobalCategories:"get-restaurants-by-global-categories",
    createOrder:"create-order",
    getOrdersByUser:"get-orders-by-user",
    getOrdersHistoryByUser:"get-orders-history-by-user",
    saveRatings:"save-ratings",
    getRatingsAndComments:"get-ratings-and-comments",
    createPaymentOrder:"create-payment-order",
    verifyPayment:"verify-payment"


}