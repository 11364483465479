import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import pi1 from "../assets/images/items/1.jpg";
const View_Orders = ({ show, setShow, dishList }) => {

    const handleClose = () => setShow(false);




    return (
        <>
            {show && <Modal show={show} centered className="animate__animated animate__fadeInUp view-orders" scrollable>
                <Modal.Header className="bg-pink justify-content-center" >
                    <Modal.Title className="fs-5 text-white ">View Orders</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-lightc">
                    <div className="container">
                        {dishList.items.length > 0 ? dishList.items.map((item) => {
                            return <div className="card-vieworder mt-3">
                                <div className="row b-dishes py-3 px-2">
                                    {/* <div className="col-3">
                                        <img
                                            src={pi1}
                                            alt=""
                                            className="img-fluid dish-img"
                                        />
                                    </div> */}
                                    <div className="col-12 align-self-center">
                                        <h6 class="text-pink fw-500">{item.dishName}</h6>
                                        <div className="d-flex  justify-content-around">

                                            <div className=" text-black  align-self-center fw-600">
                                                <b>Price :</b>  ₹ {item.price}
                                            </div>
                                            {dishList.offer ?<>  <div className="price text-black  align-self-center fw-600">
                                                <b>Offer :</b>  ₹ {dishList.offer}
                                            </div>
                                             <div className=" text-black  align-self-center fw-600">
                                             <b>Discounted Price :</b>  ₹ {dishList.discountedAmount}
                                         </div></>:""}
                                           
                                            <div className="quant ms-3">
                                                Quantity : {item.quantity}
                                            </div>
                                            <div className=" text-black  align-self-center fw-600">
                                                <b>Total :</b>   ₹ {item.totalPrice}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }) : "no data"}

                        <div className="card-vieworder mt-3">
                            <div className="row b-dishes py-3 px-2">
                                <div className="col-12">
                                    <div className="row  justify-content-end px-2 b-dishes py-3 mx-4">
                                        <div className="col-6">
                                            <p className="text-bluec mb-0 fs-14">Total </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-black mb-0 fs-14 price ">₹ {dishList.cartTotal}</p>
                                        </div>
                                    </div>
                                    <div className="row  justify-content-end px-2 b-dishes py-3 mx-4">
                                        <div className="col-6">
                                            <p className="text-bluec mb-0 fs-14">Tax and Charges </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-black mb-0 fs-14 price ">₹ {dishList.taxAndCharges}</p>
                                        </div>
                                    </div>
                                    <div className="row  justify-content-end px-2 b-dishes py-3 mx-4">
                                        <div className="col-6">
                                            <p className="text-bluec mb-0 fs-14"><b>Final Total</b> </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-black mb-0 fs-14 price ">₹ {dishList.finalAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>

                    <Button className="btn btn-custom" onClick={handleClose} >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>}
        </>
    );
}

export default View_Orders;