import React, { useEffect, useState } from 'react';
import check from "../assets/images/others/check-circle-fill.png"
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { requestPost } from '../utils/call';
import { api, socket } from '../urls';
// import { io } from "socket.io-client"
const Slot = ({ show, setShow,restaurantID }) => {
    const [people,setPeople] = useState([])
    const [slotList,setSlotLiist] = useState([])

    useEffect(()=>{
        for(let i = 1 ; i<16;i++){
           people.push({ value: i, label: i })
        }
        fetchSlots()
       
    },[])

    const fetchSlots =async ()=>{
       const res = await requestPost(api.getTimeSlot,{restaurantID})
        if(res.data){
            console.log(res.data);
           setSlotLiist(selectCreater(res.data.data,"_id","startTime","endTime"))
        }
    }
const selectCreater = (array,optionValue,optionName,secondOptionName)=>{
    let finalArray =[]
    array.map((item)=>{
        if(item.active){
            finalArray.push({value: item[optionValue], label: `${item[optionName]}  - to -  ${item[secondOptionName]}`})
        }
    })
    return finalArray
}

   
  
    const handleClose = () => setShow(false);
    return (

        <>
            <Modal show={show} onHide={() => setShow(false)} centered className='slot' size='sm' >
                <div >
                    <Modal.Body className='py-0'>

                        <div className="row justify-content-center bg-form-time">
                            <div className="col-11 ">
                                <form className='my-4'>
                                    <div className="form-group">
                                        <label htmlFor="people" className='fs-13 mb-1'>For People </label>
                                        <Select

                                            className=' fs-13 border-0'
                                            options={people}
                                        />


                                    </div>
                                    <div className="form-group my-3 phone-group">
                                        <label htmlFor="date" className='fs-13 mb-1'>Select Date </label>
                                        <input type="date" className='form-control fs-12 ps-2 text-pink border-0 py-2' />


                                    </div>
                                    <div className="form-group my-3 phone-group">
                                        <label htmlFor="date" className='fs-13 mb-1'>Select Time </label>
                                        <Select

                                            className=' fs-13 border-0'
                                            options={slotList}
                                        />


                                    </div>
                                    <div className="row mt-4 justify-content-center">

                                        <div className="col-6 ">


                                            <Link to="" className="btn text-bluec skip-btn w-100 " onClick={handleClose}>Cancel</Link>
                                        </div>
                                        <div className="col-6 ">
                                            <Link to="/confirm-order" className="btn btn-custom w-100" onClick={handleClose}>Done</Link>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                    </Modal.Body>
                </div>

            </Modal>



        </>
    )
}

export default Slot;