import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { errorToast, requestPost, successToast } from "../utils/call";
import { api } from "../urls";

const RestaurantRating = ({show,setShow,data}) => {
  const [ratingNumber, setRatingNumber] = useState(0);
  const [comment, setComment] = useState("")

  // Catch Rating value
  const handleRating = (rate) => {
    setRatingNumber(rate);
  };
  const submitRating =async ()=>{
    data.comment = comment
    data.rating=ratingNumber
   const res =await requestPost(api.saveRatings,data)
   if(res.data){
    successToast(res)
    setShow(false)
   }else{
    errorToast(res)
   }
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="login"
      >
        <Modal.Body>
          <div className="container row">
            <div className="col-md-12">
              <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
                fillColor="#6409bc"
                size={25}
                /* Available Props */
              />
              <div class="form-group">
                {/* <label for="exampleFormControlTextarea1">Comment</label> */}
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="type here..."
                  rows="7"
                  value={comment}
                  onChange={(e)=>setComment(e.target.value)}
                ></textarea>
              </div>
              <button className="btn btn-success float-right m-3" onClick={submitRating}>Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RestaurantRating;
